const Errors = {
  ERR_REFUND_ORDER_PROCESSING:
    "You have already requested a refund for this order",
  ERR_EMPLOYEE_ALREADY_EXISTS: "You already have a staff member with this info",
  ERR_UNAUTHORIZED: "Your credentials have expired, you need to log in again",
  ERR_ACCOUNT_BALANCE_LOW: "You don't have enough balance",
  ERR_ORDER_SETTLEMENT_PROCESSED:
    "Orders in which the settlement process has started cannot be refunded",
  ERR_LOGIN_CREDENTIALS: "Wrong email or password",
  ERR_PASSWORD_WRONG: "Wrong password",
  ERR_TWO_FA_ALREADY_ENABLED: "2fa already enabled",
  ERR_CONTACT_ID_INVALID: "Contact not found",
  ERR_ADDRESS_NOT_FOUND: "Address not generated yet, please contact support",
  ERR_VALIDATION: "Wrong details provided",
  ERR_EMAIL_NOT_FOUND: "Email not found",
  ERR_TWO_FA_CODE_INVALID:
    "2FA code is invalid or has expired. Please try again",
  UNKNOWN:
    "An unknown error occurred. Please try again in a few minutes. If the problem persists contact support",
  SUCCESS_LOGIN_OTP_VERIFICATION_CODE:
    "We sent a new code to your email address",
  ERR_CODE_ALREADY_SENT: "A code was already sent to your email.",
  ERR_VERIFICATION_CODE_INVALID:
    "Verification code is invalid or has expired. Please try again",
  VALIDATION_FAILED: "Please verify your email and password",
  ERR_EMAIL_OR_PASSWORD_WRONG: "Please verify your email and password",
  ERR_CAPTCHA_REQUIRED: "Please verify captcha to continue.",
  ERR_INVALID_CAPTCHA: "Invalid captcha",
  ERR_ORDER_REFUND_EXISTS: "This order has already been refunded",
  ERR_PHONE_ALREADY_EXISTS:
    "Phone number is already in use. Please verify or specify a new phone number.",
  ERR_EMAIL_ALREADY_EXISTS:
    "Email address is already in use. Please verify or specify a new email.",
  ERR_CUSTOMER_NOT_FOUND:
    "We could not find this email in our database. Please verify.",
  ERR_CUSTOMER_NAME_INVALID:
    "Name field can only contain a first and a last name.",
  ERR_TWO_FA_CODE: "2FA code is invalid or has expired. Please try again.",
  ERR_DEPOSIT_ADDRESS_REQUIRED:
    "Please verify that your deposit address is valid.",
  ERR_ORDER_PROCESSED: "Order with current state can't be refunded",
  ERR_PASSWORDS_NOT_MATCHING: "New passwords do not match. Please verify",
  ERR_AMOUNT_LOW: "Amount is less than minimum required",
  ERR_HAS_UNPAID_INVOICES:
    "You can't ask for new loan before paying active ones",
  ERR_NO_API_USERS:
    "You need to activate your API keys. Please contact support@datamynt.zendesk.com",
  ERR_NETWORK_NOT_SUPPORTED: "This network is currently not supported",
  ERR_INVALID_ADDRESS: "Please verify if all the added addresses are valid",
  ERR_ASSET_NOT_SWAPPABLE: "Asset is not swappable",
  ERR_INVOICE_NOT_PAID: "You cannot refund an invoice which is not yet paid",
  ERR_WITHDRAWAL_REQUEST_PENDING:
    "Withdrawal request can't be processed, while there is a pending withdrawal request",
  ERR_INVOICE_REFUND_EXISTS:
    "A refund has already been processed for this invoice",
  ERR_EXPORT_REQUEST_EXISTS:
    "We can't process this request as there is an existing export request",
  ERR_SETTLEMENT_ISSUE:
    "There is an issue with settlement. Please contact support for more details",
  ERR_FIAT_SETTLEMENT_RATE:
    "Rates are not available currently. Please contact support for more details",
  ERR_BANK_ACCOUNT:
    "There is a problem verifying bank account. Please contact support for more details",
  ERR_BANK_ACCOUNT_EXISTS: "Bank account details already exists",
  ERR_SETTLEMENT_AMOUNT:
    "Settlement amount can be >= 500 NGN and <= 10000000 NGN"
};

export default Errors;
