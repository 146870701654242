import { createContext, useReducer, useMemo } from "react";
import PropTypes from "prop-types";

const initialState = {
  accounts: null,
  currentPage: "Dashboard",
  contacts: null,
  user: null,
  totalBalance: null,
  totalInterest: null,
  selectedOrg: null,
  selectedAccount: null,
  twoFaEnabled: null,
  contactsUpdated: false
};

const reducer = (state, action) => {
  let newState;
  const callbacks = {
    SET_ACCOUNTS: () => {
      newState = { ...state, accounts: action.value };
    },
    SET_CONTACTS: () => {
      newState = { ...state, contacts: action.value };
    },
    SET_CURRENT_PAGE: () => {
      newState = { ...state, currentPage: action.value };
    },
    SET_USER_INFO: () => {
      newState = { ...state, user: action.value };
    },
    SET_SELECTED_ORGANISATION: () => {
      newState = { ...state, selectedOrg: action.value };
    },
    SET_TWO_FA_ENABLED: () => {
      newState = { ...state, twoFaEnabled: action.value };
    },
    SET_TOTAL_BALANCE: () => {
      newState = { ...state, totalBalance: action.value };
    },
    SET_TOTAL_INTEREST: () => {
      newState = { ...state, totalInterest: action.value };
    },
    SET_SELECTED_ACCOUNT: () => {
      newState = { ...state, selectedAccount: action.value };
    },
    SET_CONTACTS_UPDATED: () => {
      newState = { ...state, contactsUpdated: action.value };
    }
  };

  callbacks[action.type]();

  return newState;
};

const DashboardContext = createContext({
  state: initialState,
  dispatch: () => {}
});

const DashboardProvider = ({ children, initialStateFromProps }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialStateFromProps || initialState
  );

  const memoizedState = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <DashboardContext.Provider value={memoizedState}>
      {children}
    </DashboardContext.Provider>
  );
};

DashboardProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export { DashboardContext, DashboardProvider };
