import { useCallback, useState } from "react";
import Errors from "../components/errors";
import { useRouter } from "next/router";

const useErrorHandling = () => {
  const [error, setError] = useState(null);
  const router = useRouter();
  const withErrorHandling = useCallback(async (apiCall, ...params) => {
    try {
      const { data } = await apiCall(...params);
      setError(null);

      return { data, error: null };
    } catch (err) {
      // Status codes listed here should be expected errors (i.e wrong password token expiration etc)
      const ignoredErrors = [
        "ERR_UNAUTHORIZED",
        "ERR_PASSWORD_WRONG",
        "ERR_LOGIN_CREDENTIALS"
      ];
      const statusCode = err?.response?.data?.statusCode;
      const statusMessage = err?.response?.data?.statusMessage;

      if (statusCode === "ERR_UNAUTHORIZED" || statusCode === "ERR_FORBIDDEN") {
        await router.push(`/login`);
      }

      if (
        typeof window !== undefined &&
        (!statusCode || !ignoredErrors.includes(statusCode))
      ) {
        window.Rollbar?.error(err);
      }

      if (process.env.NODE_ENV === "development") {
        console.log(err);
      }

      const message =
        statusCode === "ERR_INVALID_ADDRESS"
          ? statusMessage
          : Errors[statusCode];
      message && setError(message);

      return { error: { statusCode, message } };
    }
  }, []);

  return { error, setError, withErrorHandling };
};

export default useErrorHandling;
