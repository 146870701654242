import { useState, useEffect, useContext } from "react";
import Link from "next/link";

import Image from "next/image";
import { RiCloseFill } from "react-icons/ri";
import { getCurrentTheme } from "../../helpers/theme";
import useErrorHandling from "../../hooks/useErrorHandling";
import {
  apiGetCurrencyRates,
  apiGetCurrencyRatesKES,
  apiGetCurrencyRatesNGN,
  apiNGNCurrencyPrice
} from "../../helpers/api";
import { DashboardContext } from "../../contexts/Dashboard";
import Lottie from "react-lottie";
import redDotAnimation from "../../public/red-dot.json";
import { colorFilterFactory } from "lottie-web";

export default function Banner(props) {
  const [visible, setVisible] = useState(true);
  const theme = getCurrentTheme();
  const { withErrorHandling } = useErrorHandling();
  const [error, setError] = useState("");
  const [options, setOptions] = useState([]);
  const [buyPrice, setBuyPrice] = useState(null);
  const [sellPrice, setSellPrice] = useState(null);
  const [KESBuyPrice, setKESBuyPrice] = useState(null);
  const [KESSellPrice, setKESSellPrice] = useState(null);
  const [kesOptions, setKESOptions] = useState([]);

  const [blink, setBlink] = useState(true);

  const {
    state: { selectedOrg },
    dispatch
  } = useContext(DashboardContext);

  useEffect(() => {
    const getNGNCurrencyPrice = async () => {
      const { data, error } = await withErrorHandling(apiGetCurrencyRatesNGN);
      if (error) {
        setError(error);
        setBuyPrice(null);
        setSellPrice(null);
      } else {
        setBuyPrice(data.buyPrice + " NGN");
        setSellPrice(data.sellPrice + " NGN");
        const filteredOptions = data;
        setOptions(filteredOptions);
      }
    };

    getNGNCurrencyPrice();
  }, []);

  useEffect(() => {
    const getKESCurrencyPrice = async () => {
      const { data, error } = await withErrorHandling(apiGetCurrencyRatesKES);
      if (error) {
        setError(error);
        setBuyPrice(null);
        setSellPrice(null);
      } else {
        setKESBuyPrice(data.buyPrice);
        setKESSellPrice(data.sellPrice);
        const filteredKesOptions = data;
        setKESOptions(filteredKesOptions);
      }
    };
    getKESCurrencyPrice();
  }, []);

  useEffect(() => {
    if (props.currentCurrency === "NGN") {
      setBuyPrice(options?.buyPrice ? options?.buyPrice + " NGN" : "---");
      setSellPrice(options?.sellPrice ? options?.sellPrice + " NGN" : "---");
    }
    if (props.currentCurrency === "KSH") {
      setBuyPrice(KESBuyPrice ? KESBuyPrice + " KES" : "---");
      setSellPrice(KESSellPrice ? KESSellPrice + " KES" : "---");
    }
  }, [props]);

  const redDotOptions = {
    loop: true,
    autoplay: true,
    animationData: blink ? redDotAnimation : null,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setBlink(b => !b);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div
        className={
          visible
            ? " flex items-center p-[16px] relative rounded-3xl flex flex-col md:flex-row  max-w-[520px]  mx-auto bg-[#2E2F31] text-white text-3xl font-bold font-sans text-[16px] gap-y-6 md:gap-y-0 md:gap-x-6 lg:gap-x-12 lg:ml-20 ml-8 mr-8"
            : "hidden "
        }
        style={{
          backgroundColor: ""
        }}
      >
        <div className="flex text-white text-3xl font-bold font-sans text-[16px] flex-col gap-y-4 md:flex-col ">
          <div className="flex flex-row">
            <Image src="/Group41875.svg" width={30} height={30} alt="" />
            <p className="ml-2">Current Rates </p>
          </div>
          <div className={"flex flex-col sm:flex-row"}>
            <div className="flex justify-between ">
              <p className="">
                {" "}
                <span className={"text-[#0EA363]"}> Buy: </span>1 USD={" "}
                {buyPrice ? `${buyPrice} ` : "--- "}
              </p>
            </div>

            <div className="flex justify-between">
              <div className="mt-[-10px] hidden sm:block ml-8 mr-8">
                <Image src="/Ellipse168.svg" width={6} height={6} alt="" />
              </div>{" "}
              <p className="">
                <span className={"text-[#FB547C]"}> Sell:</span> 1 USD={" "}
                {sellPrice ? `${sellPrice} ` : "--- "}
              </p>
            </div>
          </div>
        </div>

        <button
          type="button"
          className="absolute right-3 top-4 text-white text-xl h-full flex items-top md:items-top"
          onClick={() => setVisible(false)}
        >
          <RiCloseFill />
        </button>
      </div>
    </>
  );
}
