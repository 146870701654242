export const setTheme = (theme) => {
    if (typeof window !== 'undefined') {
        localStorage.setItem('theme', theme);
    }
}
export const getCurrentTheme = () => {
    if (typeof window !== 'undefined') {
        return localStorage.getItem('theme') || "light"
    }

    return "light"
}